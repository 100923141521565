import NeedleworkApi from "api/domain/NeedleworkApi";
import Color from "domain/Color";

export default class Needlework {
    width: number;
    height: number;
    colorPalette: Color[];
    items: Item[];

    constructor(from: NeedleworkApi) {
        this.width = from.width;
        this.height = from.height;
        this.colorPalette = from.color_palette.map((i) => new Color(i));
        this.items = from.x.map((x, i) => ({
            x: x,
            y: from.y[i],
            color: this.colorPalette[from.color[i]],
            type: toType(from.type[i]),
        } as Item));
    }

    convert(): NeedleworkApi {
        return {
            width: this.width,
            height: this.height,
            color_palette: this.colorPalette.map((i) => i.convert()),
            x: this.items.map((i) => i.x),
            y: this.items.map((i) => i.y),
            color: this.items.map((i) => this.colorPalette.indexOf(i.color)),
            type: this.items.map((i) => convertType(i.type)),
        };
    }
};

export interface Item {
    x: number;
    y: number;
    color: Color;
    type: Type;
};

export type Type = "X" | "/" | "\\";

export function toType(index: number): Type {
    if (index === 1) {
        return "/";
    }
    if (index === 2) {
        return "\\";
    }
    return "X";
};

export function convertType(type: Type): number {
    if (type === "/") {
        return 1;
    }
    if (type === "\\") {
        return 2;
    }
    return 0;
};

import ColorApi from "../api/domain/ColorApi";

export default class Color {
    id?: string;
    color: string;
    title?: string;

    constructor(from: ColorApi) {
        this.id = from.id;
        this.color = from.color;
        this.title = from.title;
    }


    convert(): ColorApi {
        return {
            id: this.id,
            color: this.color,
            title: this.title,
        };
    }
};

import styles from "./styles.module.css";
import * as React from "react";
import Needlework, {Item} from "domain/Needlework";

export default function Canvas(props: {
    data: Needlework
}) {
    const items = new Map<number, Map<number, Item>>();
    props.data.items.forEach((item) => {
        let inner = items.get(item.x);
        if (inner === undefined) {
            inner = new Map();
            items.set(item.x, inner);
        }
        inner.set(item.y, item);
    });

    //console.log(items);


    const rows = Array(props.data.height).fill(0).map((_, x) => {
        const columns = Array(props.data.width).fill(0).map((_, y) => {
            const item = items.get(x)?.get(y);
            const color = item?.color === undefined ? "#000000" : item?.color.color;
            const type = item?.type;
            return (
                <td className={styles.cell} key={y}>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none'
                         viewBox='0 0 100 100'>
                        {
                            (type === "X" || type === "/") && (
                                <path d='M20 0 L0 0 L0 20 L80 100 L100 100 L100 80' fill={color}/>
                            )
                        }
                        {
                            (type === "X" || type === "\\") && (
                                <path d='M0 80 L80 0 L100 0 L100 20 L20 100 L0 100' fill={color}/>
                            )
                        }
                    </svg>
                </td>
            )
        });
        return (
            <tr key={x}>
                {columns}
            </tr>
        );
    });

    return (
        <table className={styles.grid}>
            <tbody>
            {rows}
            </tbody>
        </table>
    );
}

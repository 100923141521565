import * as React from 'react';
import styles from './styles.module.css';
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import Canvas from "Canvas";
import {fetchNeedlework} from "api/Client";
import {useState} from "react";
import Needlework from "domain/Needlework";

export const NeedleworkEditor = (props: {
    baseUrl: string,
    id: string,
}) => {
    const [data, setData] = useState<Needlework>();

    if (data === undefined) {
        fetchNeedlework(props.baseUrl, props.id).then((data) => {
            setData(data);
        });
    }

    const content = data !== undefined ? (
        <Canvas data={data}/>
    ) : (
        <h3>Loading</h3>
    );


    return (
        <div className={styles.main}>
            <TransformWrapper options={{
                limitToBounds: false,
                minScale: 0.1,
                maxScale: 10,
            }}>
                <TransformComponent>
                    {content}
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
}
